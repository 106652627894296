/* eslint-disable react/require-default-props */

import React, { createContext, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SplitFactory } from '@splitsoftware/splitio-browserjs';

import SPLIT_FEATURES from './features';
import { CustomiseVoucherContext } from '../components/Context/Context';

export const SplitContext = createContext({
  loading: false,
  splits: {},
});

const { SPLIT_TOKEN } = process.env;

const SplitContextProvider = ({ children }) => {
  const [contextValue, setContextValue] = useState({
    splits: {},
    loading: true,
  });

  const userData = useContext(CustomiseVoucherContext);

  useEffect(() => {
    const userKey = userData?.user['sci:uid'] || 'gifting';
    const factory = SplitFactory({
      core: {
        authorizationKey: SPLIT_TOKEN,
        key: userKey,
      },
    });
    const client = factory.client();
    const featureList = Object.values(SPLIT_FEATURES);

    /* istanbul ignore next */
    client.on(client.Event.SDK_READY, () => {
      const treatments = client.getTreatmentsWithConfig(featureList);

      setContextValue({
        splits: treatments,
        loading: false,
      });
    });
  }, [userData]);

  return <SplitContext.Provider value={contextValue}>{children}</SplitContext.Provider>;
};

SplitContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SplitContextProvider;

export const SplitConsumer = SplitContext.Consumer;
