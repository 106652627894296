import React, { useMemo } from 'react';
import nookies from 'nookies';
import NdsThemeProvider from '@ntuctech/devex-tangram/Theme/NdsThemeProvider';
import { Auth0Provider } from '@auth0/auth0-react';
import Router from 'next/router';
import GlobalContextProvider from '../components/Context/Context';
import '../styles.css';
import { AUTH0_SCOPE, REDIRECT_URL } from '../constants/index';
import EventTrackingProvider from '../components/EventTrackingProvider/EventTrackingProvider';
import SplitContextProvider from '../SplitContext/splitConfiguration';

const onRedirectCallback = (appState) => {
  const { [REDIRECT_URL]: redirectUrl } = nookies.get();
  if (redirectUrl) {
    Router.replace(redirectUrl);
  }
  // Use Next.js Router.replace method to replace the url test
  Router.replace(appState?.returnTo || '/');
};

function MyApp({ Component, pageProps }) {
  const getRedirectUrl = useMemo(() => {
    if (process.env.IS_SERVER) {
      return '';
    }
    return window.location.origin;
  }, []);

  const renderComponent = () => {
    return (
      <Auth0Provider
        domain={process.env.AUTH0_DOMAIN}
        clientId={process.env.AUTH0_CLIENT_ID}
        redirectUri={getRedirectUrl}
        onRedirectCallback={onRedirectCallback}
        useRefreshTokens
        audience={process.env.AUTH0_AUDIENCE}
        scope={AUTH0_SCOPE}
        cacheLocation="localstorage"
      >
        <NdsThemeProvider data-testid="app">
          <GlobalContextProvider>
            <SplitContextProvider>
              <EventTrackingProvider>
                <Component {...pageProps} />
              </EventTrackingProvider>
            </SplitContextProvider>
          </GlobalContextProvider>
        </NdsThemeProvider>
      </Auth0Provider>
    );
  };

  return renderComponent();
}

export default MyApp;
