/* eslint-disable jest/no-mocks-import */
/* eslint-disable import/prefer-default-export */
import { get, post, put, patch, del } from '../fetch';

const handleCatchError = (error) => {
  // Keeping this as it will help to throw Promise.reject error
  throw error;
};

const { API_URL } = process.env;
const { PAYMENT_DOMAIN } = process.env;
const { PUBLIC_FILE } = process.env;
const { AUTH_URL } = process.env;
const { INTERNAL_API_URL } = process.env;

let url;

// sample get request
export const getRequest = async (accessToken, id) => {
  return get(`${API_URL}/api/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

// sample post request
export const postRequest = async (accessToken, id, requestData) => {
  return post(`${API_URL}/api/${id}`, {
    body: JSON.stringify(requestData),
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

export const createGuest = async (requestData) => {
  return post(`${API_URL}/api/v1/guests`, {
    body: JSON.stringify(requestData),
  });
};

export const fetchMyOrders = async (accessToken) => {
  return get(`${API_URL}/api/v1/orders`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

export const fetchSingleOrder = async (accessToken, id) => {
  return get(`${API_URL}/api/v1/orders/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

export const getGuest = async (guestId) => {
  return get(`${API_URL}/api/v1/guests/${guestId}`).catch(handleCatchError);
};

export const getUserInfoDetail = async (accessToken) => {
  return get(`${AUTH_URL}/userinfo`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

export const extendGuest = async (guestId, requestData) => {
  return post(`${API_URL}/api/v1/guests/${guestId}/extend`, {
    body: JSON.stringify(requestData),
  });
};

export const uploadImage = async (orderId, data, accessToken) => {
  return post(`${API_URL}/api/v1/orders/${orderId}/uploads`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: data,
    redirect: 'follow',
  });
};

export const getPayment = async (accessToken) => {
  return get(`${PAYMENT_DOMAIN}/api/card`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }).catch(handleCatchError);
};

export const putPayment = async (accessToken, cardId) => {
  return put(`${PAYMENT_DOMAIN}/api/card/${cardId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ default: true }),
  }).catch(handleCatchError);
};

export const CreateGuestGift = async (guestId, requestData, signature, token) => {
  if (token) {
    url = `${API_URL}/api/v1/gifts`;
  } else {
    url = `${API_URL}/api/v1/guests/${guestId}/gifts`;
  }
  return post(url, {
    body: JSON.stringify(requestData),
    headers: {
      'X-Endpoint-API-Signature': signature,
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getGuestList = async (guestId, signature) => {
  return get(`${API_URL}/api/v1/guests/${guestId}/gifts?status=GIFT_STATUS_INITIATED`, {
    headers: {
      'X-Endpoint-API-Signature': signature,
    },
  }).catch(handleCatchError);
};

export const getGuestListWithAuth = async (token, id, isB2bEnabled) => {
  const cartUrl =
    id && isB2bEnabled
      ? `${API_URL}/api/v1/gifts?status=GIFT_STATUS_INITIATED&entityId=${id}`
      : `${API_URL}/api/v1/gifts?status=GIFT_STATUS_INITIATED`;
  return get(cartUrl, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const getViewGuest = async (guestId, giftId, signature, token) => {
  if (token) {
    url = `${API_URL}/api/v1/gifts/${giftId}`;
  } else {
    url = `${API_URL}/api/v1/guests/${guestId}/gifts/${giftId}`;
  }
  return get(url, {
    headers: {
      'X-Endpoint-API-Signature': signature,
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const patchUpdateGuest = async (guestId, giftId, signature, requestData, token) => {
  if (token) {
    url = `${API_URL}/api/v1/gifts/${giftId}`;
  } else {
    url = `${API_URL}/api/v1/guests/${guestId}/gifts/${giftId}`;
  }
  return patch(url, {
    headers: {
      'X-Endpoint-API-Signature': signature,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestData),
  }).catch(handleCatchError);
};

export const postMergeGift = async (accessToken, requestData) => {
  return post(`${API_URL}/api/v1/gifts/merge`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ guestUid: requestData }),
  }).catch(handleCatchError);
};

export const deleteGuest = async (guestId, giftId, signature, token) => {
  if (token) {
    url = `${API_URL}/api/v1/gifts/${giftId}`;
  } else {
    url = `${API_URL}/api/v1/guests/${guestId}/gifts/${giftId}`;
  }
  return del(url, {
    headers: {
      'X-Endpoint-API-Signature': signature,
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const emptyCart = async (guestId, signature, token, entityId, isB2bEnabled) => {
  if (token && isB2bEnabled) {
    url = `${API_URL}/api/v1/gifts?entityId=${entityId}`;
  } else if (token) {
    url = `${API_URL}/api/v1/gifts`;
  } else {
    url = `${API_URL}/api/v1/guests/${guestId}/gifts`;
  }
  return del(url, {
    headers: {
      'X-Endpoint-API-Signature': signature,
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const getImages = async () => {
  return get(`${PUBLIC_FILE}/template.json`).catch(handleCatchError);
};

export const createPayment = async (requestData, token) => {
  return post(`${API_URL}/api/v1/payments`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(requestData),
  }).catch(handleCatchError);
};

export const getPaymentSuccess = async (paymentId, token) => {
  return get(`${API_URL}/api/v1/payments/${paymentId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const getVoucherDetails = async (slug) => {
  return get(`${API_URL}/web/v1/portal/vouchers/${slug}`).catch(handleCatchError);
};

export const decodeTokenProvision = async (accessToken) => {
  return post(`${INTERNAL_API_URL}/provision`, {
    body: JSON.stringify({ token: accessToken }),
  }).catch(handleCatchError);
};

export const getReceiverOpen = async (receiverId, token) => {
  return post(`${API_URL}/web/v1/portal/receiver/${receiverId}/open`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};

export const getCustomerEntity = async (token) => {
  return get(`${API_URL}/api/v1/customers`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).catch(handleCatchError);
};
