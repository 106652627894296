/* eslint-disable import/prefer-default-export
 */
export const AUTH0_SCOPE =
  'openid email profile offline_access scid:read:profile scid:update:profile scid:update:preferences scid:verify:myinfo scid:update:account_security scid:read:qr';
export const AUTH_TOKEN_KEY = 'auth_token';
export const REDIRECT_URL = 'redirect_url';
export const GUEST_DATA = 'guest_data';
export const CUSTOMER_UID = 'customerUid';
export const LAST_ACTIVITY = 'lastActvity';
export * from './payments';
export * from './general';
export const DEFAULT_TITLE = 'FairPrice E-Gift Voucher';
