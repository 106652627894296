import React, { Component, createContext } from 'react';
import getConfig from 'next/config';
import { GTM_ENV_VAR as GEV } from '../../lib/eventTrackerFunction';
import { sendGaEventToAmplitude, pushDataLayerToAmplitude } from '../../lib/amplitude';

import { CustomiseVoucherContext } from '../Context/Context';

/* Creating contex for event tracking. It will provide with a function named as track.
  Track: This function can be used to send the events and it will append all other values as
  undefined for dataLayer.
*/
const EventTrackingContext = createContext({
  track: () => {},
});

const {
  publicRuntimeConfig: { AMPLITUDE_TOKEN },
} = getConfig();

const { Provider, Consumer } = EventTrackingContext;

class EventTrackingProvider extends Component {
  constructor(props) {
    super(props);

    this.track = this.track.bind(this);
    this.initAmplitude = this.initAmplitude.bind(this);

    /* eslint-disable react/no-unused-state */
    this.state = {
      track: props.track || this.track,
    };
  }

  componentDidMount() {
    this.initAmplitude();
  }

  initAmplitude() {
    const { isLoggedIn, accountData } = this.props;
    let userid = '';
    if (isLoggedIn && accountData?.id) {
      userid = accountData.id;
    }
    import('amplitude-js').then((amplitude) => {
      this.amplitude = amplitude;
      window.fp_amplitude = amplitude;
      amplitude.getInstance().init(AMPLITUDE_TOKEN, userid);
    });

    pushDataLayerToAmplitude(this.amplitude);
  }

  /**
   * @param {*} testing_version
   * Reads experimentTrackingList from remote config
   * checks variant for experiments & multiVariantExperiments set in global context
   * combines all values comma separated
   * being sent in tracking as GEV.TESTING_VERSION
   * being sent to API calls
   */

  /* eslint-disable camelcase */
  track(pushEvent) {
    const { isLoggedIn, userId } = this.props;
    const { page_type, screen_name, event_category, event_action, event_label, device_id } =
      pushEvent;
    const GTEvent = {
      [GEV.LOGIN_STATUS]: isLoggedIn ? 'Yes' : 'No',
      [GEV.PAGE_TYPE]: page_type,
      [GEV.SCREEN_NAME]: screen_name,
      [GEV.EVENT_CAT]: event_category,
      [GEV.EVENT_ACTION]: event_action,
      [GEV.EVENT_LABEL]: event_label,
      [GEV.ASSET_TYPE]: 'payment-egifting',
      [GEV.USER_ID]: userId,
      [GEV.DEVICE_ID]: device_id,
      [GEV.ENVIRONMENT]: process.env.GA_ENV,
      [GEV.EVENT_NAME]: 'hm_push_event',
    };
    // send event to GA
    window.dataLayer.push(GTEvent);

    sendGaEventToAmplitude(pushEvent, this.amplitude);
  }

  render() {
    const { children } = this.props;
    return <Provider value={this.state}>{children}</Provider>;
  }
}

const EventTrackingProviderWrapper = (props) => (
  <CustomiseVoucherContext.Consumer>
    {({ isLoggedIn, userId }) => (
      <EventTrackingProvider {...props} isLoggedIn={isLoggedIn} userId={userId} />
    )}
  </CustomiseVoucherContext.Consumer>
);

EventTrackingProviderWrapper.defaultProps = {};

export const EventTrackingConsumer = Consumer;
export { EventTrackingContext };

export default EventTrackingProviderWrapper;
