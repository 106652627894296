import nookies from 'nookies';
import { useAuth0 } from '@auth0/auth0-react';
import { AUTH_TOKEN_KEY } from '../constants/index';

const useAuthenticationState = () => {
  const { [AUTH_TOKEN_KEY]: authTokenFromCookie } = nookies.get();
  const { isAuthenticated, isLoading } = useAuth0();

  return {
    isConfirmedLoggedIn: !!authTokenFromCookie || (!isLoading && isAuthenticated),
    isConfirmedNotLoggedIn: !authTokenFromCookie && !isLoading && !isAuthenticated,
    isLoggingIn: !authTokenFromCookie && isLoading,
    isConfirmedAuthenticated: !isLoading && isAuthenticated,
    isConfirmedNotAuthenticated: !isLoading && !isAuthenticated,
    isAuthenticating: isLoading,
  };
};

export default useAuthenticationState;
