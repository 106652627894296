import React, { useState, useEffect, useCallback } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import nookies from 'nookies';
import { isEmpty } from 'lodash';
import _get from 'lodash.get';
import moment from 'moment';
import jwtDecode from 'jwt-decode';
import { useRouter } from 'next/router';
import {
  postMergeGift,
  createGuest,
  getGuest,
  getGuestList,
  getGuestListWithAuth,
  getImages,
  extendGuest,
  getUserInfoDetail,
  decodeTokenProvision,
  getCustomerEntity,
} from '../../services/api/api';
import {
  AUTH0_SCOPE,
  AUTH_TOKEN_KEY,
  CUSTOMER_UID,
  GUEST_DATA,
  REDIRECT_URL,
} from '../../constants/index';
import { authTokenCookiOptions, getDomainForCookies } from '../../lib/utils';
import { isPastDate } from '../../utils/Utils';
import { getUserType, setUserType } from '../../utils/UserType';
import useAuthenticationState from '../../hooks/useAuthenticationState';

export const CustomiseVoucherContext = React.createContext();

export const destroyeeAllNookies = () => {
  nookies.destroy({}, REDIRECT_URL);
  nookies.destroy({}, GUEST_DATA);
  nookies.destroy({}, CUSTOMER_UID);
  nookies.destroy({}, 'USER_TYPE');

  // clear auth cookie
  const domain = getDomainForCookies();
  nookies.destroy({}, AUTH_TOKEN_KEY, authTokenCookiOptions(domain));
  nookies.destroy({}, AUTH_TOKEN_KEY);
};

const getImagesFromApi = async (setVoucherMetaData, setBannerImage, isB2bEnabled) => {
  const userRType = isB2bEnabled ? 'b2b' : 'b2c';
  const data = await getImages();
  const filteredDAta = data.find((d) => d[userRType])[userRType] || [];
  const getBannerImg = data.find(
    (img) => img?.type === 'banner' && !isPastDate(img?.end, true) && isPastDate(img?.start, true)
  );
  setBannerImage(getBannerImg?.png);
  setVoucherMetaData(filteredDAta);
};

const getUserId = (tokenValue, setUserId) => {
  const decodedToken = jwtDecode(tokenValue);
  const id = decodedToken['https://claims.identity.nedigital.sg/association/fairprice'];
  setUserId(id);
};

const GlobalContextProvider = ({ children }) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { [AUTH_TOKEN_KEY]: authTokenFromCookie } = nookies.get();
  const [guest, setGuest] = React.useState('');
  const [token, setToken] = useState(authTokenFromCookie || null);
  const [paymentError, setPaymentError] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [userId, setUserId] = useState(undefined);
  const [isEditMode, setIsEditMode] = useState(false);
  const [cartData, setCartData] = useState(null);
  const [voucherMetaData, setVoucherMetaData] = useState([]);
  const [entityData, setEntityData] = useState(null);
  const [customerData, setCustomerData] = useState('');
  const [selectedVoucher, setSelectedVoucher] = useState();
  const [banerImage, setBannerImage] = useState('');
  const [isB2bEnabled, setIsB2bEnabled] = useState(false);
  const [selectedMethod, setSelectedMethod] = React.useState(null);
  const [selectedCardDefault, setSelectedCardDefault] = useState({});
  let { [GUEST_DATA]: existingGuest } = nookies.get();
  existingGuest = existingGuest && !isEmpty(existingGuest) ? JSON.parse(existingGuest) : undefined;
  const { isConfirmedAuthenticated, isConfirmedNotAuthenticated, isAuthenticating } =
    useAuthenticationState();
  const { replace } = useRouter();
  console.log(
    888,
    isConfirmedAuthenticated,
    isConfirmedNotAuthenticated,
    isAuthenticating,
    !!token,
    !!authTokenFromCookie
  );

  const mergeGift = useCallback(
    async (aToken) => {
      if (existingGuest) {
        const getGuestListData = existingGuest;
        if (getGuestListData.length !== 0) {
          const { code } = await postMergeGift(aToken, existingGuest.id);
          if (code === 200) {
            const entityId = entityData?.id || '';
            const withAuthresponse = await getGuestListWithAuth(aToken, entityId, isB2bEnabled);
            const getGuestListWithAuthData = _get(withAuthresponse, 'data', []);
            setCartData(getGuestListWithAuthData);
          }
        }
      }
      nookies.destroy({}, GUEST_DATA);
    },
    [entityData?.id, existingGuest, isB2bEnabled]
  );

  const getAccessToken = useCallback(() => {
    return getAccessTokenSilently({
      audience: process.env.AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE,
    });
  }, [getAccessTokenSilently]);

  const getAccessRefreshToken = useCallback(() => {
    return getAccessTokenSilently({
      audience: process.env.AUTH0_AUDIENCE,
      scope: AUTH0_SCOPE,
      ignoreCache: true,
    });
  }, [getAccessTokenSilently]);

  const fetchCustomerEntityData = useCallback(
    async (aToken) => {
      try {
        const response = await getCustomerEntity(aToken);
        if (response.data.entities) {
          setEntityData(response.data.entities[0]);
          setCustomerData({
            name: response.data.name,
            email: response.data.email,
          });
        } else {
          setEntityData({});
        }
      } catch (error) {
        getAccessRefreshToken();
      }
    },
    [getAccessRefreshToken]
  );

  const setTokenAuth0 = useCallback(async () => {
    let aToken = await getAccessToken();
    const decodedToken = jwtDecode(aToken);
    const id = decodedToken['https://claims.identity.nedigital.sg/association/fairprice'];
    if (!id) {
      await decodeTokenProvision(token);
      aToken = await getAccessRefreshToken();
    }
    setToken(aToken);
  }, [token, getAccessRefreshToken, getAccessToken]);

  const getGiftCartListapi = useCallback(
    async (guestID, guestSignature) => {
      if (token) {
        try {
          const entityId = entityData?.id || '';
          const response = await getGuestListWithAuth(token, entityId, isB2bEnabled);
          const data = _get(response, 'data', []);
          setCartData(data);
        } catch (error) {
          getAccessRefreshToken();
        }
      } else {
        const response = await getGuestList(guestID, guestSignature);
        const data = _get(response, 'data', []);
        setCartData(data);
      }
    },
    [token, entityData?.id, isB2bEnabled, getAccessRefreshToken]
  );

  const createGuestApi = useCallback(async () => {
    const requestData = {
      ip: '127.0.0.1',
      userAgent: {
        name: navigator.appCodeName,
        browser: navigator.appName,
        version: navigator.appVersion,
        cookies: navigator.cookieEnabled,
        language: navigator.language,
        online: navigator.onLine,
        platform: navigator.platform,
        header: navigator.userAgent,
      },
    };
    const response = await createGuest(requestData);
    const data = _get(response, 'data', []);
    nookies.destroy({}, GUEST_DATA);
    nookies.set({}, [GUEST_DATA], JSON.stringify(data));
    setGuest(data);
    getGiftCartListapi(data.id, data.signature);
  }, [getGiftCartListapi]);

  const extendGuestApi = async (guestid) => {
    const requestData = {
      ip: '127.0.0.1',
      userAgent: {
        os: 'MacOS',
      },
    };
    await extendGuest(guestid, requestData)
      .then((res) => {
        setGuest({ ...res.data, signature: existingGuest.signature });
        nookies.destroy({}, GUEST_DATA);
        nookies.set(
          {},
          [GUEST_DATA],
          JSON.stringify({ ...res.data, signature: existingGuest.signature })
        );
      })
      .catch(() => {
        createGuestApi();
      });
  };

  const getUserInfo = useCallback(async (aToken) => {
    const data = await getUserInfoDetail(aToken);
    setUserInfo(data);
    getUserId(aToken, setUserId);
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes('/voucher')) {
      if (!isAuthenticating) {
        setGuest(existingGuest);
        getImagesFromApi(setVoucherMetaData, setBannerImage, isB2bEnabled);
      }
      if (isConfirmedAuthenticated && token) {
        getGiftCartListapi('', '');
      }
      if (!token && isConfirmedNotAuthenticated && existingGuest) {
        getGuest(existingGuest.id);
        getGiftCartListapi(existingGuest.id, existingGuest.signature);
      }
      if (!token && isConfirmedNotAuthenticated && !existingGuest) {
        createGuestApi();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    createGuestApi,
    token,
    getGiftCartListapi,
    isB2bEnabled,
    isAuthenticating,
    isConfirmedAuthenticated,
    isConfirmedNotAuthenticated,
  ]);

  useEffect(() => {
    if (!window.location.pathname.includes('/voucher')) {
      if (existingGuest) {
        const refreshIntervalId = setInterval(() => {
          const todaydate = new Date(); // current date
          const today = moment(todaydate).format('YYYY-MM-DD HH:mm:ss A');
          const expiry = moment(existingGuest.expiresAt).format('YYYY-MM-DD HH:mm:ss A');
          if (expiry < today) {
            extendGuestApi(existingGuest.id);
            clearInterval(refreshIntervalId);
          }
        }, 2000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConfirmedAuthenticated) {
      setTokenAuth0();
    }
  }, [isConfirmedAuthenticated, setTokenAuth0]);

  useEffect(() => {
    if (token !== null) {
      mergeGift(token);
      getUserId(token, setUserId);
    }
  }, [mergeGift, token]);

  useEffect(() => {
    if (!window.location.pathname.includes('/voucher')) {
      if (isConfirmedNotAuthenticated && authTokenFromCookie) {
        getUserInfo(authTokenFromCookie);
      }
    }
  }, [getUserInfo, authTokenFromCookie, isConfirmedNotAuthenticated]);

  useEffect(() => {
    if (token !== null && entityData === null) {
      fetchCustomerEntityData(token);
    }
  }, [entityData, fetchCustomerEntityData, token]);

  useEffect(() => {
    setIsB2bEnabled(getUserType() === 'B2B');
    if (typeof window !== 'undefined' && window.location.search.includes('type=business')) {
      setUserType('B2B', false);
      replace('/');
    }
  }, [replace]);

  return (
    <CustomiseVoucherContext.Provider
      value={{
        setGuest,
        guest,
        token,
        paymentError,
        setPaymentError,
        setIsEditMode,
        isEditMode,
        setCartData,
        cartData,
        destroyeeAllNookies,
        voucherMetaData,
        setVoucherMetaData,
        selectedVoucher,
        setSelectedVoucher,
        user: user || userInfo,
        getAccessRefreshToken,
        userId,
        banerImage,
        selectedCardDefault,
        setSelectedCardDefault,
        selectedMethod,
        setSelectedMethod,
        entityData,
        setEntityData,
        customerData,
        isB2bEnabled,
      }}
    >
      <>{children}</>
    </CustomiseVoucherContext.Provider>
  );
};
export default GlobalContextProvider;
