import nookies from 'nookies';

export const setUserType = async (selected, shouldReload) => {
  nookies.destroy({}, 'USER_TYPE');
  const options = {
    path: '/',
  };
  nookies.set(null, 'USER_TYPE', selected, options);
  if (shouldReload) {
    window.location.reload();
  }
};

export const getUserType = (ctx) => {
  const { USER_TYPE: userType } = nookies.get(ctx || {}) || {};

  return userType || 'B2C';
};
